var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"header-solid h-full px-5",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: 0 }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Change Password")])]},proxy:true}])},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Enter New Password","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        {
          rules: [
            {
              required: true,
              message: 'Please input your password!',
            },
            {
              validator: _vm.validateToNextPassword,
            } ],
        } ]),expression:"[\n        'password',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Please input your password!',\n            },\n            {\n              validator: validateToNextPassword,\n            },\n          ],\n        },\n      ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"Confirm Password","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'confirm',
        {
          rules: [
            {
              required: true,
              message: 'Please confirm your password!',
            },
            {
              validator: _vm.compareToFirstPassword,
            } ],
        } ]),expression:"[\n        'confirm',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Please confirm your password!',\n            },\n            {\n              validator: compareToFirstPassword,\n            },\n          ],\n        },\n      ]"}],attrs:{"type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" Submit ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }